<template>
  <div>
    <loading :loading="isLoading"></loading>
    <SectionHeader
      :name="translatedWorkRole.name"
      :description="translatedWorkRole.description"
      :progress="0"
      :loading="isLoading"
      hasBackButton
    />

    <div class="main-container px-md-5 py-5 overflow-x-hidden">
      <b-row>
        <b-col class="mb-3" sm="12">
          <PillsTable :items="translatedPills" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SectionHeader from '@/components/SectionHeader'
import PillsTable from '@/components/tables/Pills'

import i18nmixin from '@/services/mixins/i18n.mixins'

export default {
  name: 'WorkRoleDetail',
  mixins: [i18nmixin],
  components: { SectionHeader, PillsTable },
  methods: {
    ...mapActions({
      getWorkRoleDetail: 'categories/getWorkRoleDetail'
    })
  },
  computed: {
    ...mapGetters({
      workRole: 'categories/getWorkRoleDetail',
      isLoading: 'categories/isLoading'
    }),

    translatedWorkRole () { return this.translateObject(this.workRole) },
    translatedPills () { return this.translateObjectArray(this.workRole.pills) }
  },
  mounted () {
    this.getWorkRoleDetail(this.$route.params.id)
  }
}
</script>

<style>

</style>
